<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Resumo do caso</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>

      <form [formGroup]="fg">
        <div class="row">
          <div class="col-12 loginForm">
            <div class="patient">
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12" style="padding-left: 0;">
                <app-ui-select-patient
                  [isCreatingIndication]="true"
                  [selectedPatient]="selectedPatient"
                  name="paciente"
                  (change)="onChangePaciente($event)"
                >
                </app-ui-select-patient>
              </div>
              <div *ngIf="indicationId == 'novo' && !patientId" style="padding-top: 11px; margin-left: 10px;">
                <button class="btn-primary" type="button" (click)="addPatient()" mat-button>
                  Cadastrar cliente
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-8">
                <mat-form-field appearance="outline">
                  <mat-label>Fatos sobre o cliente</mat-label>
                  <textarea matInput formControlName="about"></textarea>
                </mat-form-field>
              </div>

              <div class="col-4">
                <mat-form-field appearance="outline">
                  <mat-label>
                    Profissão
                  </mat-label>
                  <input matInput type="text" formControlName="profession" placeholder="Profissão" />
                </mat-form-field>
              </div>
            </div>

            <ng-container>
              <div class="fileUpload">
                <label>Anexar documentos médicos</label>
                <input type="file" id="fileBtn" (change)="onFileSelect($event)" multiple />
                <button mat-fab color="primary" class="fileUploadBtn" (click)="triggerFileInput()">
                  <fa-icon icon="paperclip"></fa-icon>
                </button>
              </div>
              <div *ngIf="activeFiles.length == 0" class="fileList">Nenhum arquivo selecionado</div>
              <ul *ngIf="activeFiles.length > 0" class="fileList">
                <ng-container *ngFor="let uploadFile of activeFiles; let i = index">
                  <li *ngIf="uploadFile.isDeleted == 0">
                    <span (click)="goToFile(uploadFile)" [ngClass]="{
                              linkedText: !!uploadFile?.originalName
                            }">{{ uploadFile?.file?.name || uploadFile.originalName || uploadFile?.name }}</span>
                    <button mat-button class="removeFile" matTooltip="Remover" matTooltipPosition="above"
                      (click)="removeFile(i)"><fa-icon icon="times-circle"></fa-icon></button>
                  </li>
                </ng-container>
              </ul>
            </ng-container>

            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Benefício pleiteado</mat-label>
                  <mat-select formControlName="benefit" (selectionChange)="onBenefitChange()">
                    <mat-option *ngFor="let benefit of benefits" [value]="benefit">
                      {{ benefit }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div *ngIf="fg.value.benefit == 'Incapacidade temporária'" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <ng-container>
                  <mat-form-field appearance="outline">
                    <mat-label>Quantidade de dias</mat-label>
                    <mat-select formControlName="quantity">
                      <mat-option *ngFor="let quantity of quantities" [value]="quantity">
                        {{ quantity }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </div>

              <div *ngIf="fg.value.benefit == 'Outros'" class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <ng-container>
                  <mat-form-field appearance="outline" >
                    <mat-label>Detalhes benefício</mat-label>
                    <textarea matInput formControlName="otherDetails"></textarea>
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="actionBtn">
          <button class="btn-cancel" type="button" mat-button (click)="cancel()">Cancelar</button>
          <button class="btn-green" [disabled]="loading" type="submit" mat-button (click)="save()">Salvar</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>
