import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { DoctorService } from 'src/app/services/doctor.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-doctor-nps-modal',
  templateUrl: './doctor-nps-modal.component.html',
  styleUrls: ['./doctor-nps-modal.component.scss'],
})
export class DoctorNpsModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  npsValues = Array.from({ length: 11 }, (_, i) => i);

  private subs = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      indication: { id: number };
      doctor: { id: number; name: string };
    },
    private readonly dialogRef: MatDialogRef<DoctorNpsModalComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly loginService: LoginService,
    private readonly doctorService: DoctorService
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.loadNps();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const isValidForm = this.validateForm();

    if (isValidForm) {
      const formValue = this.form.getRawValue();
      this.subs.add(
        this.doctorService
          .applyNps({
            doctorId: this.data.doctor.id,
            indicationId: this.data.indication.id,
            ...formValue,
          })
          .pipe(
            tap(() => {
              this.loginService.showMessage(
                'Sua avaliação foi registrada com sucesso!'
              );
              this.closeModal();
            })
          )
          .subscribe()
      );
    }
  }

  isSelected(nps: number): boolean {
    const npsValueSelected = this.form.get('nps').value;
    return npsValueSelected === nps;
  }

  onNpsSelect(nps: number) {
    const npsControl = this.form.get('nps');
    npsControl.setValue(nps);
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      nps: [null, [Validators.required, Validators.min(0), Validators.max(10)]],
      comment: [null, []],
    });

    this.form.disable();
  }

  private validateForm(): boolean {
    const message = [];
    this.form.get('nps').invalid && message.push('Selecione uma nota');

    if (message.length > 0) {
      this.loginService.showMessage(message.join(', '));
      return false;
    }

    return true;
  }

  private loadNps() {
    this.doctorService
      .findNps({
        doctorId: this.data.doctor.id,
        indicationId: this.data.indication.id,
      })
      .pipe(
        tap((data) => {
          if (!!data) {
            this.form.setValue({
              nps: data.nps,
              comment: data.comment,
            });
          } else {
            this.form.enable();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
