import { Injectable } from '@angular/core';
import { TechnicalAssistanceTableData } from '../dto/technical-assistance.dto';

@Injectable({
  providedIn: 'root'
})
export class TechnicalAssistanceService {
  constructor() {}

  getTechnicalAssistanceTableData(): TechnicalAssistanceTableData[] {
    return [
      {
        aspect: 'Momento',
        preliminaryTechnicalOpinion: 'Antes da perícia',
        objectionReport: 'Após a perícia',
        completeTechnicalAssistance: 'Antes, durante e após a perícia'
      },
      {
        aspect: 'Função dos quesitos',
        preliminaryTechnicalOpinion: 'Direcionar a perícia',
        objectionReport: 'Contrapor o laudo pericial',
        completeTechnicalAssistance: 'Direcionar a perícia e contestar inconsistências'
      },
      {
        aspect: 'Foco',
        preliminaryTechnicalOpinion: 'Antecipar pontos críticos',
        objectionReport: 'Identificar inconsistências',
        completeTechnicalAssistance: 'Antecipar, acompanhar e corrigir aspectos críticos'
      },
      {
        aspect: 'Outros elementos',
        preliminaryTechnicalOpinion: 'Quesitos preliminares',
        objectionReport: 'Quesitos complementares',
        completeTechnicalAssistance: 'Recomendações de exames, estratégias técnicas, acompanhamento e quesitação completa'
      },
      {
        aspect: 'Finalidade',
        preliminaryTechnicalOpinion: 'Preparar para a perícia',
        objectionReport: 'Corrigir ou contestar o laudo',
        completeTechnicalAssistance: 'Garantir suporte técnico completo ao longo de todo o processo'
      }
    ];
  }
}
