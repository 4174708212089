<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Contratante</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <div class="loading-screen" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>

      <form *ngIf="!loading" [formGroup]="fg">
        <div class="row">
          <div class="col-3">
            <p></p>
            <mat-form-field appearance="outline">
              <mat-label>Documento</mat-label>
              <mat-select
                formControlName="documentType"
                required
              >
                <mat-option *ngFor="let document of [ 'CPF', 'CNPJ' ]" [value]="document">
                  {{ document }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-9">
            <p></p>
            <mat-form-field appearance="outline">
              <mat-label>{{ fg.controls.documentType.value }}</mat-label>
              <input
                matInput
                formControlName="document"
                [specialCharacters]="['/', '.', '-']"
                [mask]="
                  fg.controls.documentType.value == 'CPF'
                    ? '000.000.000-00'
                    : '00.000.000/0000-00'
                "
                (change)="onChangeDocument()"
                required
              />
              <mat-error *ngIf="fg.hasError('invalid', ['document'])">
                {{ fg.controls.documentType.value }} inválido
              </mat-error>

              <mat-error *ngIf="fg.hasError('alreadyExist', ['document'])">
                Usuário já cadastrado
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Nome completo</mat-label>
              <input
                matInput
                formControlName="name"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>E-mail</mat-label>
              <input
                matInput
                formControlName="email"
                (change)="onChangeEmail()"
                required
              />
              <mat-error *ngIf="fg.hasError('invalid', ['email'])">
                E-mail inválido
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>Celular</mat-label>
              <input
                matInput
                formControlName="mobile"
                [specialCharacters]="['-', '(', ')', ' ']"
                [mask]="'(00) 00000-0000'"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>

      <div *ngIf="!loading" class="action-buttons">
        <button
          mat-button
          class="btn-cancel"
          (click)="goBack()"
        >
          <fa-icon icon="times"></fa-icon>
          Cancelar
        </button>

        <button
          mat-button
          class="btn-green"
          [disabled]="fg.invalid"
          (click)="save()"
        >
          <fa-icon icon="check"></fa-icon>
          Salvar
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>
