<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Editar indicação</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>

    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 busca-paciente">
      <app-ui-select-patient
        [selectedPatient]="selectedPatient"
        [getAll]="true"
        (change)="onChangePaciente($event)"
      >
      </app-ui-select-patient>
    </div>

    <div class="loading-screen" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <mat-card-content *ngIf="indication && !loading">
      <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <mat-form-field appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" (selectionChange)="onStatusChange()">
                <mat-option *ngFor="let status of statusList" [value]="status">
                  {{ status }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div *ngIf="form.value.status === 'Agendado'">
              <p>Agendamento</p>
              <mat-form-field appearance="outline">
                <mat-label>Data de agendamento</mat-label>
                <input matInput [matDatepicker]="picker" (dateChange)="onScheduleDateChange($event.value)" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.controls.attendanceDate.hasError('required')">
                  Este campo é obrigatório
                </mat-error>
              </mat-form-field>

              <!-- Seletor de Horário -->
              <mat-form-field appearance="outline">
                <mat-label>Horário de agendamento</mat-label>
                <input matInput type="time" (change)="onScheduleTimeChange($event.target.value)" />
                <mat-error *ngIf="form.controls.attendanceDate.hasError('required')">
                  Este campo é obrigatório
                </mat-error>
              </mat-form-field>
            </div>



            <div *ngIf="form.value.status === 'Atendido'">
              <p>Atendimento</p>
              <mat-form-field appearance="outline">
                <mat-label>Data de atendimento</mat-label>
                <input matInput [matDatepicker]="picker" (dateChange)="onAttendanceDateChange($event.value)" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.controls.attendanceDate.hasError('required')">
                  Este campo é obrigatório
                </mat-error>
              </mat-form-field>

              <!-- Seletor de Horário -->
              <mat-form-field appearance="outline">
                <mat-label>Horário de atendimento</mat-label>
                <input matInput type="time" (change)="onAttendanceTimeChange($event.target.value)" />
                <mat-error *ngIf="form.controls.attendanceDate.hasError('required')">
                  Este campo é obrigatório
                </mat-error>
              </mat-form-field>
            </div>


            <mat-form-field appearance="outline">
              <mat-label>Link de teleconsulta</mat-label>
              <input
                matInput
                formControlName="teleconsultationURL"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Link para documento</mat-label>
              <input
                matInput
                formControlName="documentUrl"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="button-container">
          <button
            mat-button
            class="btn-green"
          >
            <mat-spinner [diameter]="18" *ngIf="saving" class="btn-white"></mat-spinner>
            Salvar
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>
