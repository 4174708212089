import { Injectable } from '@angular/core';
import { Patient } from '../interfaces/patient.interface';
import { Observable } from 'rxjs';
import { ApiServiceService } from './api-service.service';
import { LocalStorageUtil } from '../util/local-storage-util';
import { LoginService } from './login.service';
import { DefaultResponseDTO } from '../dto/default/default-response.dto';
import { PatientByNameDTO } from '../dto/patient-by-name.dto';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private apiService: ApiServiceService,
    private loginService: LoginService
  ) { }

  public getPatientList(params?:PatientServiceGetListParam):Observable<{
    list:Array<Patient>,
    total:number,
  }> {
    let url:string = `/patient/list`;
    const paramString = this.transformParamsIntoString(params)
    if (paramString) url+= paramString;
    return this.apiService.get(url)
  }

  public getPatientById(id?:number):Observable<Patient> {
    let url:string = `/patient/id/${id}`;
    return this.apiService.get(url)
  }

  public savePatient(patient: Patient):Observable<Patient> {
    const signature = LocalStorageUtil.getSignature()
    patient.planName = (signature.planName && signature.planName != '')  ? signature.planName : 'Plano Básico';

    let url:string = `/patient/insert`;
    return this.apiService.post(url, patient)
  }

  public patientExist(cpf:string):Observable<boolean> {
    let url:string = `/patient/exist/${cpf}`;
    return this.apiService.get(url)
  }

  getByName(name: string, getAll=false): Observable<DefaultResponseDTO<PatientByNameDTO[]>> {
    return this.apiService.get(`/patient/getByName?buscar=${name}${getAll ? '&getAll=true' : ''}`);
  }

  private transformParamsIntoString(params:object):string {
    if (!params) return null;

    let paramString;

    const processObj = (obj:{[key:string]:any}, parentParam:string = null) => {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          if (value != undefined) {
            if (Object.prototype.toString.call(value) === '[object Object]') {
              processObj(value, key);
            } else {
              let param = key;
              if (parentParam) param = `${parentParam}[${param}]`
              if (!paramString) {
                paramString = `?${param}=${value}`;
              } else {
                paramString += `&${param}=${value}`;
              }
            }
          }
        }
      }
    }

    processObj(params);

    return paramString;
  }
}

export interface PatientServiceGetListParam {
  page?:number;
  pageSize?:number;
  search?:string;
  filters?:{
    isDeleted?:number,
  };
  order?: {
    item?:string;
    direction?:'asc' | 'desc';
  }
}
