import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditIndicationService } from '../../services/edit-indication.service';
import { LoginService } from 'src/app/services/login.service';
import { IndicationsService } from 'src/app/services/indications.service';

@Component({
  selector: 'app-edit-indication',
  templateUrl: './edit-indication.component.html',
  styleUrls: ['./edit-indication.component.scss']
})
export class EditIndicationComponent implements OnInit {
  form: FormGroup;
  saving = false;
  loading = false;
  scheduleDatePart: Date | null = null;
  scheduleTimePart: string | null = null;
  attendanceDatePart: Date | null = null;
  attendanceTimePart: string | null = null;
  indication: any;
  selectedPatient: any;
  statusList = [
    'Aguardando agendamento',
    'Aguardando pagamento',
    'Agendado',
    'Cancelou a consulta',
    'Faltou à consulta',
    'Reagendado',
    'Atendido',
  ];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly editIndicationService: EditIndicationService,
    private readonly indicationService: IndicationsService,
    private readonly loginService: LoginService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      indicationId: [1, Validators.required],
      status: ['', Validators.required],
      teleconsultationURL: [''],
      documentUrl: [''],
      attendanceDate: [''],
      scheduleDate: [''],
    });
  }

  onStatusChange() {
    if (this.form.value.status === 'Atendido') {
      this.form.get('attendanceDate').setValidators([Validators.required]);
    } else {
      this.form.get('attendanceDate').clearValidators();
      this.form.get('attendanceDate').setValue(null);
      this.attendanceDatePart = null;
      this.attendanceTimePart = null;
    }

    if (this.form.value.status === 'Agendado') {
      this.form.get('scheduleDate').setValidators([Validators.required]);
    } else {
      this.form.get('scheduleDate').clearValidators();
      this.form.get('scheduleDate').setValue(null);
      this.scheduleDatePart = null;
      this.scheduleTimePart = null;
    }
    this.form.get('attendanceDate').updateValueAndValidity();
    this.form.get('scheduleDate').updateValueAndValidity();
  }

  onAttendanceDateChange(date: Date) {
    this.attendanceDatePart = date;
    this.updateAttendanceDateTime();
  }

  onScheduleDateChange(date: Date) {
    this.scheduleDatePart = date;
    this.updateScheduleDateTime();
  }

  onAttendanceTimeChange(time: string) {
    this.attendanceTimePart = time;
    this.updateAttendanceDateTime();
  }

  onScheduleTimeChange(time: string) {
    this.scheduleTimePart = time;
    this.updateScheduleDateTime();
  }

  private updateAttendanceDateTime() {
    if (this.attendanceDatePart && this.attendanceTimePart) {
      const [hours, minutes] = this.attendanceTimePart.split(':').map(Number);
      const combinedDateTime = new Date(this.attendanceDatePart);
      combinedDateTime.setHours(hours, minutes);
      this.form.get('attendanceDate')?.setValue(combinedDateTime);
    }

    if (!this.attendanceTimePart || !this.attendanceDatePart) {
      this.form.get('attendanceDate')?.setValue(null);
    }
  }

  private updateScheduleDateTime() {
    if (this.scheduleDatePart && this.scheduleTimePart) {
      const [hours, minutes] = this.scheduleTimePart.split(':').map(Number);
      const combinedDateTime = new Date(this.scheduleDatePart);
      combinedDateTime.setHours(hours, minutes);
      this.form.get('scheduleDate')?.setValue(combinedDateTime);
    }

    if (!this.scheduleTimePart || !this.scheduleDatePart) {
      this.form.get('scheduleDate')?.setValue(null);
    }
  }

  onChangePaciente(event) {
    this.selectedPatient = event;

    if (this.selectedPatient?.id) {
      this.loading = true;
      this.indicationService.getLastIndicationByPatient(this.selectedPatient.id)
        .subscribe({
          next: (indication) => {
            this.loading = false;
            this.indication = null;
            if (!indication) {
              return this.loginService.showMessage('Indicação não encontrada');
            }
            this.indication = indication;

            this.form.patchValue({
              indicationId: indication.id,
              status: indication.status,
              teleconsultationURL: indication.communicareTeleconsultationURL,
              documentUrl: indication.documentUrl,
              attendanceDate: indication.attendanceDate,
              scheduleDate: indication.scheduleDate,
            });
            this.onStatusChange();
          },
          error: (err) => {
            this.loading = false;
            console.error(err);
            this.loginService.showMessage('Erro ao buscar indicação');
          }
        });
    }


  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.saving = true;
    this.editIndicationService.editInication(this.form.value)
      .subscribe({
        next: () => {
          this.saving = false;
          this.loginService.showMessage('Indicação atualizada com sucesso');
        },
        error: (err) => {
          console.error(err);
          this.loginService.showMessage('Erro ao atualizar indicação');
          this.saving = false;
        }
      });
  }
}
