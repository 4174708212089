<app-loading *ngIf="loading"></app-loading>
<ng-container *ngIf="!loading">
  <div *ngIf="dataSource.data.length == 0">
    <h3>Nenhuma indicação encontrada</h3>
  </div>

  <div *ngIf="dataSource.data.length > 0">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="patient">
        <th mat-header-cell *matHeaderCellDef>Nome do cliente</th>
        <td mat-cell *matCellDef="let element">{{ element.patient }}</td>
      </ng-container>

      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cpf | mask : "999.999.999-99" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="scheduleDate">
        <th mat-header-cell *matHeaderCellDef>Data do agendamento</th>
        <td mat-cell *matCellDef="let element">
          {{
            element.communicarePaymentLink &&
            element.status === scheduleStatus.WAITING_SCHEDULE &&
            element.scheduleDate
              ? "-"
              : element.scheduleDate
              ? element.scheduleDate
              : "-"
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="doctorName">
        <th mat-header-cell *matHeaderCellDef>Médico</th>
        <td mat-cell *matCellDef="let element">
          {{ element.doctorName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element" class="actions">
          <button
            mat-mini-fab
            *ngIf="
              tab == 'schedule' && element.status != scheduleStatus.CANCELED
            "
            matTooltip="Editar"
            matTooltipPosition="above"
            (click)="goToSaveIndication(element.id)"
          >
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>
          <button
            *ngIf="tab == 'schedule' && element.status != scheduleStatus.SCHEDULED && element.status != scheduleStatus.WAITING_SCHEDULE && !!element?.communicarePaymentLink"
            mat-mini-fab
            matTooltip="Copiar link de pagamento"
            matTooltipPosition="above"
            (click)="openLinkInNewTab(element.communicarePaymentLink)"
          >
            <fa-icon icon="dollar-sign"></fa-icon>
          </button>
          <button
            *ngIf="
              tab == 'schedule' && element.status == scheduleStatus.SCHEDULED
            "
            mat-mini-fab
            matTooltip="Copiar link da teleconsulta"
            matTooltipPosition="above"
            (click)="copyTeleconsultationLink(element.communicareTeleconsultationURL)"
          >
            <fa-icon icon="tv"></fa-icon>
          </button>
          <button
            *ngIf="
              tab == 'schedule' &&
              element.status === scheduleStatus.WAITING_SCHEDULE
            "
            mat-mini-fab
            matTooltip="Agendar"
            matTooltipPosition="above"
            (click)="openAgendaOnlineModal(element)"
          >
            <fa-icon icon="calendar-alt"></fa-icon>
          </button>
          <button
            mat-mini-fab
            *ngIf="
              tab == 'schedule' &&
              (element.status === scheduleStatus.MISSED ||
                element.status === scheduleStatus.SCHEDULED)
            "
            matTooltip="Reagendar"
            matTooltipPosition="above"
            (click)="openAgendaOnlineModal(element, true)"
          >
            <fa-icon icon="calendar-alt"></fa-icon>
          </button>
          <button
            *ngIf="tab == 'attendance'"
            mat-mini-fab
            [matTooltip]="element.documentUrl ? 'Ver laudo' : 'Laudo pendente'"
            matTooltipPosition="above"
            [ngClass]="
              element.documentUrl || !isNewDate(element.scheduleDate)
                ? ''
                : 'removeBtn'
            "
            (click)="openDocument(element.documentUrl)"
          >
            <fa-icon icon="briefcase-medical"></fa-icon>
          </button>

          <button
            *ngIf="tab == 'attendance' && element.documentUrl && element.doctorId"
            mat-mini-fab
            matTooltip="Avaliar Médico"
            matTooltipPosition="above"
            (click)="
              openDoctorNpsModal({
                indication: { id: element.id },
                doctor: {
                  id: element.doctorId,
                  name: element.doctorName
                }
              })
            "
          >
            <fa-icon icon="star"></fa-icon>
          </button>

          <button
            *ngIf="
              tab == 'schedule' &&
              (element.status == scheduleStatus.WAITING_SCHEDULE ||
                element.status == scheduleStatus.CANCELED)
            "
            mat-mini-fab
            matTooltip="Arquivar indicação"
            matTooltipPosition="above"
            (click)="toggleArchiveStatus(element.id, element.status)"
          >
            <fa-icon icon="archive"></fa-icon>
          </button>

          <button
            *ngIf="
              tab == 'archive' && element.status == scheduleStatus.ARCHIVED
            "
            mat-mini-fab
            matTooltip="Desarquivar indicação"
            matTooltipPosition="above"
            (click)="toggleArchiveStatus(element.id, element.status)"
            class="removeBtn"
          >
            <fa-icon icon="archive"></fa-icon>
          </button>

          <button
            *ngIf="
              tab == 'schedule' &&
              (element.status == scheduleStatus.SCHEDULED ||
                element.status == scheduleStatus.WAITING_PAYMENT)
            "
            mat-mini-fab
            [matTooltip]="
              element.status === scheduleStatus.SCHEDULED
                ? 'Cancelar agendamento'
                : 'Cancelar reserva'
            "
            matTooltipPosition="above"
            class="removeBtn"
            (click)="cancelSchedule(element.id, element.communicareScheduleId)"
          >
            <fa-icon icon="trash"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</ng-container>
