<app-ui-main-menu>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Assinatura</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <app-loading *ngIf="loading"></app-loading>

    <mat-card-content *ngIf="!loading && !signature?.digitalManagerData">
      <h1>Escolha seu Plano</h1>
      <div class="planSchema">
        <div *ngFor="let plan of signaturePlans">

          <app-pricing-card
            [planName]="plan.previdasDetails.name"
            [planValue]=plan.previdasDetails.planValue
            [maxUsers]="plan.previdasDetails.maxUsers"
            [scheduleAndSupportPriority]="plan.previdasDetails.scheduleAndSupportPriority"
            [maxAppointmentsPerYear]="plan.previdasDetails.maxAppointmentsPerYear"
            [OccupationalHealthAndMedicalExpertise]="plan.previdasDetails.pricePerConsultation.OccupationalHealthAndMedicalExpertise"
            [OthersAreas]="plan.previdasDetails.pricePerConsultation.OthersAreas"
          >
            <div class="btn-div" *ngFor="let details of [ plan.details[0] ]">
                
              <button
                *ngIf="!signature?.active"
                mat-button
                class="btn"
                (click)="goToPlan(details.checkout_url)"
              > 
                Assinar agora
              </button>

              <button 
                *ngIf="signature?.active && !signature?.digitalManagerData"
                mat-button class="btn" 
                (click)="openIntercomChat()"
                matTooltip="Você já assinou o {{signature?.planName}}. Fale com um consultor para migrar de plano."
              >
                Fale com Consultor
              </button>
            </div>
          </app-pricing-card>
        </div>
        <p><b>Nota:</b> Os valores apresentados correspondem à parcela mensal do plano anual, divididos em 12 vezes no pagamento. </p>
        <!-- div class="mobileBorder" *ngFor="let plan of signaturePlans">
          <div class="title">
            <h2 class="planName">{{plan.previdasDetails.name}}</h2>
            <h2 class="planSubName">{{plan.previdasDetails.describle}}</h2>
          </div>

         


          <div class="middleInfo">

            <span>Usuários: {{plan.previdasDetails.maxUsers}} </span>
            <mat-divider></mat-divider>
            
            <span>Agenda e Suporte: {{plan.previdasDetails.scheduleAndSupportPriority}} </span>
            <mat-divider></mat-divider>

            <span>Agendamentos por ano: {{plan.previdasDetails.maxAppointmentsPerYear}} </span>
            <mat-divider></mat-divider>

            <span>Preço por Consulta - Perícia Médica e Medicina do Trabalho: R$ {{plan.previdasDetails.pricePerConsultation.OccupationalHealthAndMedicalExpertise}} </span>
            <mat-divider></mat-divider>

            <span>Preço por Consulta - Áreas Especificas: R$ {{plan.previdasDetails.pricePerConsultation.OthersAreas}} </span>
            <mat-divider></mat-divider>

            <div class="btn-div" *ngFor="let details of [ plan.details[0] ]">
              
              <button
                *ngIf="!signature?.active"
                mat-button
                class="btn-sign"
                (click)="goToPlan(details.checkout_url)"
              > 
                Assinar agora
              </button>

              <button 
                *ngIf="signature?.active && !signature?.digitalManagerData"
                mat-button class="planBtn" 
                (click)="openIntercomChat()"
                matTooltip="Você já assinou o {{signature?.planName}}. Fale com um consultor para migrar de plano."
              >
                Fale com Consultor
              </button>
            </div>
          </div>
        </div -->
      </div>
    </mat-card-content>

    <mat-card-content *ngIf="!loading && signature?.digitalManagerData">
      <!-- Detalhes da assinatura -->
      <h1>Detalhes da Assinatura</h1>
      <div class="signatureDetails">
        <div>
          <div class="flex-div">
            <h2>Plano</h2>
            <div [ngClass]="signature?.digitalManagerData.cancel_at_cycle_end || signature.suspended ? 'canceled' : 'active'">
              {{ signature?.digitalManagerData.cancel_at_cycle_end || signature.suspended ? 'Inativo' : 'Ativo' }}
            </div>
          </div>
          
          <p>{{signature?.digitalManagerData.product.name}}</p>
        </div>

        <div>
          <h2>Método de pagamento</h2>
          <p>{{ signature?.digitalManagerData.payment_method == 'credit_card' ? 'Cartão de crédito' : 'Pix' }}</p>
        </div>

        <div>
          <h2>Assinado em</h2>
          <!-- Converter numero para data -->
          <p>{{ signature?.digitalManagerData.created_at * 1000 | date: 'DD/MM/yyyy' }}</p>
        </div>

        <div>
          <h2>
            {{ 
              signature?.suspended || signature?.digitalManagerData.cancel_at_cycle_end ? 
              'Disponível até' : 
              'Próximo pagamento' 
            }} 
          </h2>
          <p>{{ signature?.digitalManagerData.next_cycle_at | date }}</p>
        </div>  

        <div *ngIf="!signature?.suspended && !signature?.digitalManagerData.cancel_at_cycle_end">
          <button
            mat-button
            class="btn-cancel-sign"
            (click)="cancelSignature()"
          >
            Cancelar Assinatura
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>
