<section>
  <h2>Nos ajude a avaliar este relatório médico elaborado pelo profissional.</h2>
  <p>A avaliação reflete exclusivamente a experiência com o médico responsável por este relatório. Sua opinião nos ajuda a manter a qualidade do serviço</p>
  <h3>{{ data.doctor.name }}</h3>

  <form [formGroup]="form" (submit)="onSubmit()">
    <div class="nps">
      <button
        *ngFor="let value of npsValues"
        mat-stroked-button
        type="button"
        (click)="onNpsSelect(value)"
        [disabled]="form.disabled"
        [ngClass]="{ 'btn-primary': isSelected(value) }"
      >
        {{ value }}
      </button>
    </div>

    <div>
      <label><b>Deixe seu comentário:</b></label>    
      <mat-form-field class="example-full-width" floatLabel="never">
        <textarea placeholder="Descreva pontos positivos e/ou sugestões de melhoria sobre o relatório emitido pelo médico" matInput rows="5" formControlName="comment"></textarea>
      </mat-form-field>
    </div>
    <div class="actions">
      <button
        mat-flat-button
        class="btn-primary"
        type="submit"
        [disabled]="form.disabled"
      >
        Avaliar
      </button>
      <button mat-flat-button (click)="closeModal()">Cancelar</button>
    </div>
  </form>
</section>
