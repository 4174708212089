import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TakeAccountRequestDTO, TakeAccountResponseDTO } from '../dto/take-account.dto';
import { environment } from 'src/environments/environment';
import { EditIndicationRequestDTO } from '../dto/edit-indication.dto';

@Injectable({
  providedIn: 'root'
})
export class EditIndicationService {
  private readonly apiUrl = environment.api;

  constructor(
    private readonly http: HttpClient,
  ) {}

  editInication(data: EditIndicationRequestDTO): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/admin/edit-indication`, data);
  }
}
