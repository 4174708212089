<div class="pricing-card">
    <div class="header">
        <h3>{{planName}}</h3>
    </div>
    <div class="price">
        <span class="currency">R$</span><span class="amount">{{planValue}}</span><span class="frequency">/mês*</span>
        <p class="annual-text">Anual</p> 
    </div>
    <ul class="features">
        <li><strong class="green">✔</strong> Número de Usuários: <strong class="green">{{maxUsers}}</strong></li>
        <li><strong class="green">✔</strong> Agenda e Suporte: <strong class="green">{{scheduleAndSupportPriority}}</strong></li>
        <li><strong class="green">✔</strong> Agendamentos por ano: <strong class="green">{{displayMaxAppointmentsPerYear}}</strong></li>
        <li><strong class="green center"> Preço por Consulta </strong></li>
        <li><strong class="green">✔</strong>Áreas Especificas: <strong class="green">R$ {{formatValue(OthersAreas)}}</strong></li>
        <li><strong class="green">✔</strong>Perícia Médica e Medicina do Trabalho: <strong class="green">R$ {{formatValue(OccupationalHealthAndMedicalExpertise)}}</strong></li>
    </ul>
    <ng-content></ng-content>
</div>