<app-ui-main-menu>
  <mat-card>
    <mat-card-header class="d-flex flex-column">
      <mat-card-title>Solicite Assistência Técnica Médica Ideal</mat-card-title>
      <mat-card-content>
        Conte com pareceres técnicos preliminares, relatórios para impugnação, análises de viabilidade processual e assistência médica completa, sob medida para suas necessidades jurídicas.
      </mat-card-content>
    </mat-card-header>

    <mat-divider></mat-divider>

    <mat-card-content class="table-container">
      <div class="row">
        <div class="col-sm-12" *ngIf="technicalAssistanceTableData?.data?.length > 0">
          <table
            mat-table
            [dataSource]="technicalAssistanceTableData"
          >
            <ng-container matColumnDef="aspect">
              <th mat-header-cell *matHeaderCellDef>Aspecto</th>
              <td mat-cell *matCellDef="let element">
                <strong>{{ element.aspect }}</strong>
              </td>
            </ng-container>

            <ng-container matColumnDef="preliminaryTechnicalOpinion">
              <th mat-header-cell *matHeaderCellDef>Parecer técnico preliminar</th>
              <td mat-cell *matCellDef="let element">
                {{ element.preliminaryTechnicalOpinion }}
              </td>
            </ng-container>

            <ng-container matColumnDef="objectionReport">
              <th mat-header-cell *matHeaderCellDef>Relatório para impugnação</th>
              <td mat-cell *matCellDef="let element">
                {{ element.objectionReport }}
              </td>
            </ng-container>

            <ng-container matColumnDef="completeTechnicalAssistance">
              <th mat-header-cell *matHeaderCellDef>Assistência técnica completa</th>
              <td mat-cell *matCellDef="let element">
                {{ element.completeTechnicalAssistance }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: columns; let element"
              patientTooltip
            ></tr>
          </table>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-center mt-4">
          <button
            mat-button
            class="btn-green"
          >
            Solicitar Proposta Médica
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</app-ui-main-menu>

