import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { Observable } from 'rxjs';
import { DoctorNPS } from '../interfaces/doctor-nps.interface';

type FindDoctorNpsDTO = {
  indicationId: number;
  doctorId: number;
};

type ApplyDoctorNpsDTO = {
  indicationId: number;
  doctorId: number;
  nps: number;
  comment: string;
};

@Injectable({
  providedIn: 'root',
})
export class DoctorService {
  constructor(private readonly apiService: ApiServiceService) {}

  applyNps({
    doctorId,
    indicationId,
    ...body
  }: ApplyDoctorNpsDTO): Observable<void> {
    return this.apiService.post(
      `/doctor/${doctorId}/nps/${indicationId}`,
      body
    );
  }

  findNps({ doctorId, indicationId }: FindDoctorNpsDTO): Observable<DoctorNPS> {
    return this.apiService.get(`/doctor/${doctorId}/nps/${indicationId}`);
  }
}
