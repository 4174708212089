import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Patient } from '../../interfaces/patient.interface'
import { User } from '../../interfaces/user.interface'
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { PatientService, PatientServiceGetListParam } from 'src/app/services/patient.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})
export class PatientsComponent implements OnInit {
  loading:boolean = false;
  patientList: MatTableDataSource<Patient> = new MatTableDataSource([]);
  searching:boolean = false;
  user: User;
  paginatorLength:number = 0;
  paginatorPageSize:number = 20;
  paginatorPage:number = 1;
  search:string = '';
  showAddPatient:boolean = true;
  saving: boolean = false;

  order:{item?:string, direction?:'asc' | 'desc'} = {};


  filter = 'Ativos';
  filters: { id: string; name: string }[] = [
    { id: 'Ativos', name: 'Ativos' },
    { id: 'Inativos', name: 'Inativos' },
  ];

  columns: string[] = [
    'name',
    'cpf',
    'birthdate',
    'mobile',
    'actions'
  ];

  constructor(
    private readonly patientService:PatientService,
    private router:Router,
    private loginService:LoginService,
  ) { }

  ngOnInit(): void {
    this.load()
  }

  async load() {
    try {
      await this.getAndSetPatientList();
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  paginatorHandler(patientList:Array<Patient>) {
    this.paginatorLength = patientList.length * 4;
    this.patientList.data = patientList;
  }

  async sort(event: any): Promise<void> {
    try {
      this.order.item = event.active;
      this.order.direction = event.direction;
      if (!this.order.direction) this.order = {};
      await this.getAndSetPatientList();
    } catch (error) {
      this.loginService.showMessage('Erro ao ordenar lista', 3000)
      return;
    }
  }

  async changeIsDeleted(patient:Patient):Promise<void> {
    try {
      patient.isDeleted = patient.isDeleted ? 0 : 1;
      await this.savePatient({id: patient.id, isDeleted: patient.isDeleted})
      await this.load()
    } catch (error) {
      throw error
    }
  }

  async onChangePage(event: PageEvent): Promise<void> {
    this.paginatorPage = event?.pageIndex + 1 || this.paginatorPage;
    const params:PatientServiceGetListParam = this.generatePatientServiceGetListParam();
    await this.getAndSetPatientList(params)
  }

  onKeyUpBuscar(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      if (this.searching) {
        this.loginService.showMessage('Por favor aguarde o resultado para realizar uma nova busca', 3000)
        return;
      }
      this.onClickBuscar();
      return;
    }
  }

  async onChangeFilter() {
    await this.onClickBuscar();
  }

  async onClickBuscar(): Promise<void> {
    this.searching = true
    const params:PatientServiceGetListParam = this.generatePatientServiceGetListParam();
    params.page = 1;
    await this.getAndSetPatientList(params)
    this.searching = false
  }

  async getAndSetPatientList(params?:PatientServiceGetListParam) {
    try {
      this.loading = true;
      if (!params) params = this.generatePatientServiceGetListParam();
      const {list, total} = await this.getPatientList(params);
      this.patientList.data = list;
      this.paginatorLength = total;
      this.loading = false;
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  generatePatientServiceGetListParam():PatientServiceGetListParam {
    const params:PatientServiceGetListParam = {
      page: this.paginatorPage,
      pageSize: this.paginatorPageSize,
    }

    if (this.filter) {
      const filters = {
        'Ativos': 0,
        'Inativos': 1,
      };
      const isDeleted = filters[this.filter];
      params.filters = {};
      params.filters.isDeleted = isDeleted;
    }

    if (this.search) params.search = this.search;

    if (this.order?.item && this.order?.direction) {
      params.order = {
        item: this.order.item,
        direction: this.order.direction
      }
    }

    return params;
  }

  async getPatientList(params:PatientServiceGetListParam):Promise<{
    list:Array<Patient>,
    total:number,
  }> {
    try {
      return await this.patientService.getPatientList(params).toPromise()
    } catch (error) {
      console.log(error)
      throw error
    }
  }


  goToPatientRegister() {
    this.router.navigate(['/patients/register/new'])
  }

  async savePatient(patient:Patient):Promise<any> {
    try {
      if (patient) {
        this.saving = true;
        const response = await this.patientService.savePatient(patient).toPromise()
        this.saving = false;

        this.loginService.showMessage('Cliente atualizado com sucesso!', 3000)
      }
    } catch (error) {
      throw error
    }
  }

}
