import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from './../../services/login.service';
import { Component, OnInit } from '@angular/core';
import { Views } from '../views';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { LocalStorageUtil } from 'src/app/util/local-storage-util';
import { SignatureService } from 'src/app/services/signature.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends Views implements OnInit {
  private unsubscribe$: Subject<void> = new Subject();
  public loging: boolean;
  public urlParams
  public logo: string = 'assets/logoRegister.png';
  public missingPassword: string = '/forgot-password';
  hidePassword = true;
  firstFormGroup = new UntypedFormGroup({
    firstCtrl: new UntypedFormControl(['', Validators.required]),
  });
  secondFormGroup = new UntypedFormGroup({
    secondCtrl: new UntypedFormControl(['', Validators.required]),
  });
  token = '';

  constructor(
    private fb: UntypedFormBuilder,
    private snack: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private signatureService: SignatureService,
  ) {
    super();
    this.buildForm();
    this.token = this.activatedRoute.snapshot.params['token'];
  }

  buttonDisabled = false;

  public ngOnInit() {
    this.initForm(this.fb);
    LocalStorageUtil.logout();
    this.loading = false;

    if (this.token)
      this.checkToken();

  }

  checkToken() {
    this.loading = true;
    this.loginService.showMessage('Validando o link, aguarde', 6000);
    this.loginService.checkToken(this.token).subscribe(
      res => {
        if (res.status) {
          this.router.navigate([`/complete-registration/${this.token}`])
        } else {
          this.loginService.showMessage('Erro no validar link', 6000);
          this.router.navigate([`/login`]);
        }
      },
      error => {
        this.loginService.showMessage('Erro no validar link', 6000);
        this.router.navigate([`/login`]);
      }
    )
  }

  private buildForm() {
    this.campos = {
      id: [],
      password: [],
    };
  }

  public login() {
    if (this.fg.invalid) {
      this.snack.open('Preencha os campos corretamente', 'ok', { duration: 3000 });
      return;
    }

    this.loading = true;
    this.loginService.login(this.fg.value).subscribe({
      next: res => {
        LocalStorageUtil.setToken(res.data.accessToken);

        this.loginService.startIntercom(res.data.accountInfo)

        this.signatureService
          .getAccountSignature()
          .subscribe(
            (signature) => {
              LocalStorageUtil.setAccountInfo(res.data.accountInfo);

              if (signature && signature.data && signature.data.suspended) {
                this.router.navigate(['/signature']);
              } else {
                this.router.navigate(['/indications']);
              }

              this.loading = false;
            },
            (err) => {
              console.error('Assinatura não encontrada!');
              this.loading = false;
              this.router.navigate(['/signature']);
            }
          );

      },
      error: error => {
        this.snack.open('Usuário ou senha incorretos', 'ok', { duration: 3000 });
        this.loading = false;
      }
    })

  }

  public goToRegister() {
    this.router.navigate(['/register'])
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
