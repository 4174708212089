import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiMainMenuComponent } from 'src/app/shared/components/ui-main-menu/ui-main-menu.component';
import { UiSelectPatientComponent } from 'src/app/shared/components/ui-select-patient/ui-select-patient.component';
import { LocalFontAwesome } from 'src/app/shared/modules/fontawesome/fontawesome.module';
import { LocalMaterialModule } from 'src/app/shared/modules/material/material.module';

@NgModule({
  imports: [
    CommonModule,
    LocalMaterialModule,
    LocalFontAwesome,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [UiMainMenuComponent, UiSelectPatientComponent],
  exports: [
    CommonModule,
    LocalMaterialModule,
    LocalFontAwesome,
    FormsModule,
    ReactiveFormsModule,
    UiMainMenuComponent,
    UiSelectPatientComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
