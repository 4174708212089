import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss']
})
export class PricingCardComponent implements OnInit {
  @Input() planName: string = '';
  @Input() planValue: number;
  @Input() maxUsers: number;
  @Input() scheduleAndSupportPriority: string;
  @Input() maxAppointmentsPerYear: number;
  @Input() OccupationalHealthAndMedicalExpertise: number = 0;
  @Input() OthersAreas: number = 0;

  displayMaxAppointmentsPerYear: string
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    // Sempre que o valor mudar, atualiza o displayValue
    this.displayMaxAppointmentsPerYear = this.maxAppointmentsPerYear >= 999 ? 'Ilimitado' : this.maxAppointmentsPerYear.toString();
  }

  formatValue (value: number): string {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

}
