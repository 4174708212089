import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { TechnicalAssistanceComponent } from './pages/technical-assistance/technical-assistance.component';

@NgModule({
  imports: [
    SharedModule,
    MatTableModule,
    MatButtonModule
  ],
  declarations: [
    TechnicalAssistanceComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class TechnicalAssistanceModule {}
