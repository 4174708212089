import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TechnicalAssistanceTableData } from '../../dto/technical-assistance.dto';
import { TechnicalAssistanceService } from '../../services/technical-assistance.service';

@Component({
  selector: 'app-technical-assistance',
  templateUrl: './technical-assistance.component.html',
  styleUrls: ['./technical-assistance.component.scss']
})
export class TechnicalAssistanceComponent implements OnInit {
  technicalAssistanceTableData: MatTableDataSource<TechnicalAssistanceTableData>;
  columns: string[] = [
    'aspect',
    'preliminaryTechnicalOpinion',
    'objectionReport',
    'completeTechnicalAssistance'
  ];

  constructor(
    private readonly technicalAssistanceService: TechnicalAssistanceService,
  ) {}

  ngOnInit(): void {
    this.technicalAssistanceTableData = new MatTableDataSource(
      this.technicalAssistanceService.getTechnicalAssistanceTableData()
    );
  }
}
